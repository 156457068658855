import styled from 'styled-components';

import colours from '_theming/colours';

/**
 * Button.
 */
const Button = styled.button`
  font-family: inherit;
  color: ${colours.daintree};
  background-color: ${(props) => props.bgcolor || colours.white};
  text-decoration: none;
  text-align: center;

  border: 0;
  border-radius: 4px;
  padding: 12px 14px;

  box-shadow: 0 2px 4px 0 rgba(0, 43, 61, 0.2);

  cursor: pointer;
`;

export default Button;
