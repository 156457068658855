import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { navigate } from 'gatsby';

import {
  ContainerPage,
  Margin,
  ContainerNavigation,
  ContainerLandingSection,
  ContainerTinySectionDivider,
  ContainerTinyFooterDivider,
  ContainerFooter,
} from '_templates/Pages';
import colours from '_theming/colours';
import ContactSalesTeam from '_sections/ContactSales/ContactSalesTeam';
import Curved from '_components/Curved';
import Footer from '_sections/Footer';
import Layout from '_components/Layout';
import NavigationBar from '_components/NavigationBar';
import useSiteMetadata from '_hooks/useSiteMetadata';

/**
 * Landing Section container.
 */
const CustomContainerLandingSection = styled(ContainerLandingSection)`
  background-color: ${colours.white};
`;

/**
 * Contact Sales page.
 *
 * @param {object} props Component props.
 * @param {object} props.location Router Location object.
 * @returns {React.Component} Contact Sales page.
 */
const ContactSalesPage = ({ location }) => {
  const { siteUrl } = useSiteMetadata();

  const pageUrl = `${siteUrl}${location.pathname}`;
  const title = 'Contact World Class Merchant Brokers';
  const description =
    'Talk with trusted industry experts with over 30 years of experience that pride themselves on offering the best and most personable service in the business.';
  const imageUrl = `${siteUrl}/images/SalesTeam.png`;
  const imageAltText = 'Sales people standing by to assist in business success';
  const jsonLD = JSON.stringify(
    {
      '@context': 'https://schema.org',
      '@type': 'WebPage',
      name: 'Get a Quote',
      description: description,
      breadcrumb: {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            item: {
              '@type': 'WebSite',
              '@id': siteUrl,
              name: 'Pricematepay',
            },
          },
          {
            '@type': 'ListItem',
            position: 2,
            item: {
              '@type': 'WebPage',
              '@id': pageUrl,
              name: 'Contact',
            },
          },
        ],
      },
      publisher: {
        '@type': 'Organization',
        name: 'Pricematepay',
        logo: {
          '@type': 'ImageObject',
          url: `${siteUrl}/images/logos/Pricematepay/LightBlue.png`,
        },
        url: siteUrl,
        contactPoint: {
          '@type': 'ContactPoint',
          contactType: 'Sales',
          telephone: '[+1-647-494-3992]',
          email: 'sales@pricematepay.com',
          areaServed: ['CA', 'US'],
          availableLanguage: 'EN',
        },
      },
    },
    null,
    2
  );

  /**
   * Redirects to the form submission success page.
   */
  const handleRedirectToSuccessPage = () => {
    navigate('/contact/thank-you/');
  };

  return (
    <Layout>
      <Helmet defer={false}>
        <title>{title} - Pricematepay</title>
        <link rel="canonical" href={pageUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />
        <meta name="og:image:width" content="1200" />
        <meta name="og:image:height" content="630" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image:alt" content={imageAltText} />
        <meta name="twitter:image" content={imageUrl} />
        <meta name="twitter:image:width" content="1200" />
        <meta name="twitter:image:height" content="630" />
        <meta name="description" content={description} />
        <script type="application/ld+json">{jsonLD}</script>
      </Helmet>

      <ContainerPage>
        <ContainerNavigation>
          <Margin>
            <NavigationBar />
          </Margin>
        </ContainerNavigation>

        <ContainerTinySectionDivider>
          <Curved preserveAspectRatio="none" fillColor={colours.daintree} />
        </ContainerTinySectionDivider>

        <CustomContainerLandingSection>
          <Margin>
            <ContactSalesTeam handleRedirectToSuccessPage={handleRedirectToSuccessPage} />
          </Margin>
        </CustomContainerLandingSection>

        <ContainerTinyFooterDivider>
          <Curved preserveAspectRatio="none" fillColor={colours.daintree} />
        </ContainerTinyFooterDivider>

        <ContainerFooter bgColor={colours.daintree}>
          <Margin>
            <Footer />
          </Margin>
        </ContainerFooter>
      </ContainerPage>
    </Layout>
  );
};

export default ContactSalesPage;
