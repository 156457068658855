import styled from 'styled-components';

import breakpoints from '_util/responsiveDesign/breakpoints';

// Extract responsive design variables.
const { mobile } = breakpoints;

/**
 * Table.
 */
const Table = styled.div`
  display: table;
  width: 100%;

  table-layout: fixed;

  @media screen and (max-device-width: ${mobile.max}px) {
    border-spacing: 2px;
  }

  border-spacing: 10px;
`;

/**
 * Table row.
 */
const Row = styled.div`
  display: table-row;
`;

/**
 * Table cell.
 */
const Cell = styled.div`
  display: table-cell;
`;

export { Table, Row, Cell };
