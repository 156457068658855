import React from 'react';
import styled from 'styled-components';

import Spinner from '_components/Spinner';

/**
 * Component container.
 */
const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;

  svg {
    max-width: 350px;
    width: 100%;
    height: auto;
  }
`;

/**
 * Loading spinner.
 *
 * @returns {React.Component} Loading spinner.
 */
const Loading = () => (
  <Container>
    <Spinner />
  </Container>
);

export default Loading;
