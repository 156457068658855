import React from 'react';
import styled from 'styled-components';

/**
 * Field label.
 */
const Label = styled.label`
  display: block;
`;

/**
 * Text input field.
 */
const Input = styled.input`
  background-color: #ffffff;

  border: 0;
  border-radius: 2px;

  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  box-sizing: border-box;

  width: 100%;
  padding: 5px 10px;

  font-family: inherit;
  font-size: 1rem;
`;

/**
 * Checkbox field.
 */
const Checkbox = styled(Input)`
  width: inherit;
`;

/**
 * Dropdown field.
 */
const Dropdown = styled.select`
  background-color: #ffffff;

  border: 0;
  border-radius: 2px;

  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  box-sizing: border-box;

  width: 100%;
  padding: 5px 10px;

  font-family: inherit;
  font-size: 1rem;
`;

/**
 * Textarea field.
 */
const Textarea = styled.textarea`
  background-color: #ffffff;

  border: 0;
  border-radius: 2px;

  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  box-sizing: border-box;

  width: 100%;
  padding: 5px 10px;

  font-family: inherit;
  font-size: 1rem;

  resize: none;

  min-height: 200px;
`;

/**
 * Name label.
 *
 * @returns {React.Component} Label.
 */
const NameLabel = () => <Label htmlFor="name">Name*</Label>;

/**
 * Name field.
 *
 * @returns {React.Component} Field.
 */
const NameField = () => <Input id="name" type="input" name="Name" maxLength="50" required />;

/**
 * Email Address label.
 *
 * @returns {React.Component} Label.
 */
const EmailAddressLabel = () => <Label htmlFor="email">Email Address*</Label>;

/**
 * Email Address field.
 *
 * @returns {React.Component} Field.
 */
const EmailAddressField = () => (
  <Input id="email" type="email" name="_replyto" maxLength="50" required />
);

/**
 * Phone Number label.
 *
 * @returns {React.Component} Label.
 */
const PhoneNumberLabel = () => <Label htmlFor="phone">Phone Number</Label>;

/**
 * Phone Number field.
 *
 * @returns {React.Component} Field.
 */
const PhoneNumberField = () => <Input id="phone" type="tel" name="Phone Number" maxLength="20" />;

/**
 * Country label.
 *
 * @returns {React.Component} Label.
 */
const CountryLabel = () => <Label htmlFor="country">Country</Label>;

/**
 * Country field.
 *
 * @returns {React.Component} Field.
 */
const CountryField = () => (
  <Dropdown id="country" name="Country" defaultValue="">
    <option value="" disabled>
      Select a country
    </option>
    <option value="Canada">Canada</option>
    <option value="United States">United States</option>
  </Dropdown>
);

/**
 * Company Website label.
 *
 * @returns {React.Component} Label.
 */
const CompanyWebsiteLabel = () => <Label htmlFor="website">Company Website</Label>;

/**
 * Company Website field.
 *
 * @returns {React.Component} Field.
 */
const CompanyWebsiteField = () => (
  <Input id="website" type="text" name="Company Website" maxLength="100" />
);

/**
 * Estimated Monthly Payment Volume label.
 *
 * @returns {React.Component} Label.
 */
const EstimatedMonthlyPaymentVolumeLabel = () => (
  <Label htmlFor="estimated-monthly-payment-volume">Estimated Monthly Payment Volume</Label>
);

/**
 * Estimated Monthly Payment Volume field.
 *
 * @returns {React.Component} Field.
 */
const EstimatedMonthlyPaymentVolumeField = () => (
  <Dropdown
    id="estimated-monthly-payment-volume"
    name="Estimated Monthly Payment Volume"
    defaultValue=""
  >
    <option value="" disabled>
      Select a monthly amount
    </option>
    <option value="None, just getting started">None, just getting started</option>
    <option value="Less than $50,000">Less than $50,000</option>
    <option value="$50,000 to $100,000">$50,000 to $100,000</option>
    <option value="$100,000 to $1,000,000">$100,000 to $1,000,000</option>
    <option value="$1,000,000 to $10,000,000">$1,000,000 to $10,000,000</option>
    <option value="More than $10,000,000">More than $10,000,000</option>
  </Dropdown>
);

/**
 * Where Do You Accept Payments label.
 *
 * @returns {React.Component} Label.
 */
const WhereDoYouAcceptPaymentsLabel = () => <div>Where do you accept payments?</div>;

/**
 * Where Do You Accept Payments - Store front field.
 *
 * @returns {React.Component} Field.
 */
const WhereDoYouAcceptPaymentsStoreFrontField = () => (
  <Label htmlFor="where-payments-accepted-store-front">
    <Checkbox
      id="where-payments-accepted-store-front"
      type="checkbox"
      name="Where do you accept payments?"
      value="Store front"
    />
    Store front
  </Label>
);

/**
 * Where Do You Accept Payments - Office field.
 *
 * @returns {React.Component} Field.
 */
const WhereDoYouAcceptPaymentsOfficeField = () => (
  <Label htmlFor="where-payments-accepted-office">
    <Checkbox
      id="where-payments-accepted-office"
      type="checkbox"
      name="Where do you accept payments?"
      value="Office"
    />
    Office
  </Label>
);

/**
 * Where Do You Accept Payments - Home field.
 *
 * @returns {React.Component} Field.
 */
const WhereDoYouAcceptPaymentsHomeField = () => (
  <Label htmlFor="where-payments-accepted-home">
    <Checkbox
      id="where-payments-accepted-home"
      type="checkbox"
      name="Where do you accept payments?"
      value="Home"
    />
    Home
  </Label>
);

/**
 * Where Do You Accept Payments - Online field.
 *
 * @returns {React.Component} Field.
 */
const WhereDoYouAcceptPaymentsOnlineField = () => (
  <Label htmlFor="where-payments-accepted-online">
    <Checkbox
      id="where-payments-accepted-online"
      type="checkbox"
      name="Where do you accept payments?"
      value="Online"
    />
    Online
  </Label>
);

/**
 * Where Do You Accept Payments - Face to Face field.
 *
 * @returns {React.Component} Field.
 */
const WhereDoYouAcceptPaymentsFaceToFaceField = () => (
  <Label htmlFor="where-payments-accepted-face-to-face">
    <Checkbox
      id="where-payments-accepted-face-to-face"
      type="checkbox"
      name="Where do you accept payments?"
      value="Face to Face"
    />
    Face to Face
  </Label>
);

/**
 * Tell Us More About Your Business label.
 *
 * @returns {React.Component} Label.
 */
const TellUsMoreAboutYourBusinessLabel = () => (
  <Label htmlFor="tell-us-more">Tell us more about your business, needs and timeline:</Label>
);

/**
 * Tell Us More About Your Business field.
 *
 * @returns {React.Component} Field.
 */
const TellUsMoreAboutYourBusinessField = () => (
  <Textarea id="tell-us-more" name="Tell us more about your business" maxLength="5000"></Textarea>
);

/**
 * Field to prevent spam.
 *
 * @returns {React.Component} Field.
 */
const GotchaField = () => <input type="text" name="_gotcha" style={{ display: 'none' }} />;

export {
  NameLabel,
  NameField,
  EmailAddressLabel,
  EmailAddressField,
  PhoneNumberLabel,
  PhoneNumberField,
  CountryLabel,
  CountryField,
  CompanyWebsiteLabel,
  CompanyWebsiteField,
  EstimatedMonthlyPaymentVolumeLabel,
  EstimatedMonthlyPaymentVolumeField,
  WhereDoYouAcceptPaymentsLabel,
  WhereDoYouAcceptPaymentsStoreFrontField,
  WhereDoYouAcceptPaymentsOfficeField,
  WhereDoYouAcceptPaymentsHomeField,
  WhereDoYouAcceptPaymentsOnlineField,
  WhereDoYouAcceptPaymentsFaceToFaceField,
  TellUsMoreAboutYourBusinessLabel,
  TellUsMoreAboutYourBusinessField,
  GotchaField,
};
