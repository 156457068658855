import React from 'react';
import styled from 'styled-components';

import { ContainerOneColumn, ContainerGraphic, H1 } from '_templates/LandingSection';
import breakpoints from '_util/responsiveDesign/breakpoints';
import colours from '_theming/colours';
import ExternalLink from '_components/ExternalLink';
import SalesTeamIllustration from '_images/svg/illustrations/SalesTeam.svg';

import Form from './components/Form';

// Extract responsive design variables.
const { tablet, desktop } = breakpoints;

/**
 * Component container.
 */
const Container = styled(ContainerOneColumn)`
  color: ${colours.daintree};
`;

/**
 * List of contacts.
 */
const List = styled.ul`
  font-family: 'Big Caslon Medium', serif;

  padding: 0;
  list-style-type: none;

  @media screen and (max-device-width: ${tablet.max}px) {
    text-align: center;
    font-size: 1rem;
  }

  @media screen and (min-device-width: ${desktop.min}px) {
    font-size: 1.25rem;
  }

  margin-bottom: 50px;
`;

/**
 * List item.
 */
const Item = styled.li`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

/**
 * Container for a two column layout.
 */
const ContainerTwoColumn = styled.div`
  display: flex;

  @media screen and (max-device-width: ${tablet.max}px) {
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;

    & > * {
      flex-basis: 100%;
    }
  }

  @media screen and (min-device-width: ${desktop.min}px) {
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;

    & > * {
      flex-basis: 49%;
    }
  }
`;

/**
 * Text container.
 */
const ContainerText = styled.div`
  p:first-child {
    margin: 0;
  }

  @media screen and (max-device-width: ${tablet.max}px) {
    margin-top: 25px;
  }

  width: 100%;
`;

/**
 * Custom hyperlink.
 */
const CustomExternalLink = styled(ExternalLink)`
  color: inherit;
  text-decoration: none;

  @media screen and (max-device-width: ${tablet.max}px) {
    display: block;
  }
`;

/**
 * Contact Sales section.
 *
 * @param {object} props Component props.
 * @param {function} props.handleRedirectToSuccessPage Redirects to the success page.
 * @returns {React.Component} Section.
 */
const ContactSales = ({ handleRedirectToSuccessPage }) => (
  <Container>
    <H1 textAlign="center">Contact our Sales Team</H1>

    <p>
      Interested in a smarter payment solution? Tell us a bit about your business by filling out the
      form below and start bringing home more of your hard earned money.
    </p>

    <ContainerTwoColumn>
      <div>
        <Form handleRedirectToSuccessPage={handleRedirectToSuccessPage} />
      </div>

      <ContainerText>
        <p>
          Whether you’re an existing Pricematepay customer who requires support, or just prefer to
          reach out directly, contact us by email or phone. We’d love to hear from you:
        </p>

        <List>
          <Item>
            Email:{' '}
            <CustomExternalLink href="mailto:sales@pricematepay.com">
              sales@pricematepay.com
            </CustomExternalLink>
          </Item>
          <Item>
            Phone: <CustomExternalLink href="tel:1-647-494-3992">1-647-494-3992</CustomExternalLink>
          </Item>
        </List>

        <ContainerGraphic>
          <SalesTeamIllustration />
        </ContainerGraphic>
      </ContainerText>
    </ContainerTwoColumn>
  </Container>
);

export default ContactSales;
