import React from 'react';

import {
  NameLabel,
  NameField,
  EmailAddressLabel,
  EmailAddressField,
  PhoneNumberLabel,
  PhoneNumberField,
  CountryLabel,
  CountryField,
  CompanyWebsiteLabel,
  CompanyWebsiteField,
  EstimatedMonthlyPaymentVolumeLabel,
  EstimatedMonthlyPaymentVolumeField,
  WhereDoYouAcceptPaymentsLabel,
  WhereDoYouAcceptPaymentsStoreFrontField,
  WhereDoYouAcceptPaymentsOfficeField,
  WhereDoYouAcceptPaymentsHomeField,
  WhereDoYouAcceptPaymentsOnlineField,
  WhereDoYouAcceptPaymentsFaceToFaceField,
  TellUsMoreAboutYourBusinessLabel,
  TellUsMoreAboutYourBusinessField,
  GotchaField,
} from './Fields';
import { Table, Row, Cell } from './Table';

/**
 * Form fields for the Desktop layout.
 *
 * @returns {React.Component} Fields.
 */
const DesktopFieldset = () => (
  <React.Fragment>
    <Table>
      <Row>
        <Cell>
          <NameLabel />
        </Cell>
        <Cell>
          <NameField />
        </Cell>
      </Row>
      <Row>
        <Cell>
          <EmailAddressLabel />
        </Cell>
        <Cell>
          <EmailAddressField />
        </Cell>
      </Row>
      <Row>
        <Cell>
          <PhoneNumberLabel />
        </Cell>
        <Cell>
          <PhoneNumberField />
        </Cell>
      </Row>
      <Row>
        <Cell>
          <CountryLabel />
        </Cell>
        <Cell>
          <CountryField />
        </Cell>
      </Row>
      <Row>
        <Cell>
          <CompanyWebsiteLabel />
        </Cell>
        <Cell>
          <CompanyWebsiteField />
        </Cell>
      </Row>
      <Row>
        <Cell>
          <EstimatedMonthlyPaymentVolumeLabel />
        </Cell>
        <Cell>
          <EstimatedMonthlyPaymentVolumeField />
        </Cell>
      </Row>
    </Table>

    <div style={{ padding: '5px 10px 0 10px' }}>
      <WhereDoYouAcceptPaymentsLabel />
    </div>

    <Table>
      <Row>
        <Cell>
          <WhereDoYouAcceptPaymentsStoreFrontField />
        </Cell>
        <Cell>
          <WhereDoYouAcceptPaymentsOfficeField />
        </Cell>
        <Cell>
          <WhereDoYouAcceptPaymentsHomeField />
        </Cell>
      </Row>
      <Row>
        <Cell>
          <WhereDoYouAcceptPaymentsOnlineField />
        </Cell>
        <Cell>
          <WhereDoYouAcceptPaymentsFaceToFaceField />
        </Cell>
      </Row>
    </Table>

    <div style={{ paddingTop: '10px' }}>
      <TellUsMoreAboutYourBusinessLabel />
    </div>

    <TellUsMoreAboutYourBusinessField />

    <GotchaField />
  </React.Fragment>
);

export default DesktopFieldset;
