import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ID = 'id-svg-comet-gradient';

/**
 * Comet icon.
 *
 * @returns {React.Component} SVG icon.
 */
const Comet = ({ nucleusFillColor, tailStartColor, tailEndColor }) => (
  <svg viewBox="0 0 40 40">
    <circle style={{ fill: 'none' }} cx="20.8" cy="17.8" r="16" />
    <linearGradient
      id={ID}
      gradientUnits="userSpaceOnUse"
      x1="28.578"
      y1="28.4187"
      x2="28.9037"
      y2="5.5872"
    >
      <stop offset="0" style={{ stopColor: tailStartColor, stopOpacity: '0.66' }} />
      <stop offset="1" style={{ stopColor: tailEndColor, stopOpacity: '0.33' }} />
    </linearGradient>
    <path
      d="M20.8,0c4.8-0.2,9.6,1.5,13.1,4.7c3.7,3.4,5.9,8.1,6.1,13.1c0.2,5.2-1.6,10.2-5.1,14.1
			c-1.8,1.9-3.9,3.5-6.3,4.6c-0.6,0.3-1.2,0.5-1.9,0.8s-1.3,0.4-1.9,0.6c-1.3,0.4-2.7,0.5-4,0.4c-2.3-0.4-3.9-2.6-3.5-5
			c0.2-1.3,1-2.4,2.1-3l1.4-0.8c0.4-0.1,0.7-0.2,1.1-0.1l1.1-0.1l1.1-0.2c0.4-0.1,0.7-0.2,1.1-0.3c1.5-0.5,2.9-1.3,4.1-2.3
      c2.5-2.2,4-5.3,4.3-8.6c0.2-3.5-0.9-6.9-3.2-9.6c-2.4-2.8-5.9-4.5-9.6-4.7l0,0c-1,0-1.8-0.8-1.8-1.8S19.8,0,20.8,0z"
      fill={`url(#${ID})`}
    />
    <circle style={{ fill: nucleusFillColor }} cx="20.8" cy="33.8" r="6.2" />
  </svg>
);

Comet.propTypes = {
  nucleusFillColor: PropTypes.string,
  tailStartColor: PropTypes.string,
  tailEndColor: PropTypes.string,
};

Comet.defaultProps = {
  nucleusFillColor: '#ffffff',
  tailStartColor: '#ffffff',
  tailEndColor: '#ffffff',
};

/**
 * Container to add animations to an SVG.
 *
 * @returns {React.Component} styled component.
 */
const Container = styled.div`
  animation: animation-name-loading-spin 1.1s infinite linear;

  @keyframes animation-name-loading-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

/**
 * Spinner.
 *
 * @returns {React.Component} Spinner.
 */
const Spinner = ({ ...props }) => (
  <Container>
    <Comet {...props} />
  </Container>
);

export default Spinner;
