import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useForm } from '@formspree/react';

import breakpoints from '_util/responsiveDesign/breakpoints';
import Button from '_components/Button';
import colours from '_theming/colours';
import useIsClient from '_hooks/useIsClient';
import useResponsive from '_hooks/useResponsive';

import DesktopFieldset from './DesktopFieldset';
import Loading from './Loading';
import MobileFieldset from './MobileFieldset';

// Extract responsive design variables.
const { tablet, desktop } = breakpoints;

/**
 * Form.
 */
const Container = styled.form`
  border-radius: 4px;
  padding: 0px;

  background-color: ${colours.linkWater};
  color: ${colours.daintree};
`;

/**
 * Container for the form content.
 */
const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  padding: 16px;
`;

/**
 * Button container.
 */
const ContainerButton = styled.div`
  @media screen and (max-device-width: ${tablet.max}px) {
    margin-top: 10px;

    & > button {
      width: 100%;
    }
  }

  @media screen and (min-device-width: ${desktop.min}px) {
    margin-top: 16px;
  }

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

// Formspree configuration.
const options = {
  data: {
    _subject: 'Sales Inquiry via Website',
  },
};

/**
 * Contact form.
 *
 * @param {object} props Component props.
 * @param {function} props.handleRedirectToSuccessPage Redirects to the success page.
 * @returns {React.Component} Contact form.
 */
const Form = ({ handleRedirectToSuccessPage }) => {
  const { isDesktop } = useResponsive();
  const isClient = useIsClient();

  // Form state.
  const [state, handleSubmit] = useForm('mdopqdel', options);

  useEffect(() => {
    if (state.succeeded) {
      handleRedirectToSuccessPage();
    }
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

  // Hide form when redirecting to the success page.
  if (state.succeeded) {
    return null;
  }

  /**
   * Renders form content.
   *
   * @returns {React.Component} Form content.
   */
  const renderContent = () => {
    if (state.submitting) {
      return <Loading />;
    }

    return (
      <React.Fragment>
        {isDesktop ? <DesktopFieldset /> : <MobileFieldset />}

        <ContainerButton>
          <Button type="submit" bgcolor={colours.redDamask}>
            Submit
          </Button>
        </ContainerButton>
      </React.Fragment>
    );
  };

  return (
    <Container onSubmit={handleSubmit}>
      {isClient ? <Content>{renderContent()}</Content> : null}
    </Container>
  );
};

export default Form;
