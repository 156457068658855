import React from 'react';
import styled from 'styled-components';

import {
  NameLabel,
  NameField,
  EmailAddressLabel,
  EmailAddressField,
  PhoneNumberLabel,
  PhoneNumberField,
  CountryLabel,
  CountryField,
  CompanyWebsiteLabel,
  CompanyWebsiteField,
  EstimatedMonthlyPaymentVolumeLabel,
  EstimatedMonthlyPaymentVolumeField,
  WhereDoYouAcceptPaymentsLabel,
  WhereDoYouAcceptPaymentsStoreFrontField,
  WhereDoYouAcceptPaymentsOfficeField,
  WhereDoYouAcceptPaymentsHomeField,
  WhereDoYouAcceptPaymentsOnlineField,
  WhereDoYouAcceptPaymentsFaceToFaceField,
  TellUsMoreAboutYourBusinessLabel,
  TellUsMoreAboutYourBusinessField,
  GotchaField,
} from './Fields';
import { Table, Row, Cell } from './Table';

/**
 * Divider.
 */
const Divider = styled.div`
  margin: 15px 0;
`;

/**
 * Form fields for mobile layout.
 *
 * @returns {React.Component} Form fields.
 */
const MobileFieldset = () => (
  <React.Fragment>
    <Table>
      <Row>
        <Cell>
          <NameLabel />
          <NameField />
        </Cell>
      </Row>

      <Row>
        <Cell>
          <EmailAddressLabel />
          <EmailAddressField />
        </Cell>
      </Row>

      <Row>
        <PhoneNumberLabel />
        <PhoneNumberField />
      </Row>

      <Row>
        <Cell>
          <CountryLabel />
          <CountryField />
        </Cell>
      </Row>

      <Row>
        <Cell>
          <CompanyWebsiteLabel />
          <CompanyWebsiteField />
        </Cell>
      </Row>

      <Row>
        <Cell>
          <EstimatedMonthlyPaymentVolumeLabel />
          <EstimatedMonthlyPaymentVolumeField />
        </Cell>
      </Row>
    </Table>

    <Divider>
      <div style={{ paddingLeft: '2px' }}>
        <WhereDoYouAcceptPaymentsLabel />
      </div>

      <Table>
        <Row>
          <Cell>
            <WhereDoYouAcceptPaymentsStoreFrontField />
            <WhereDoYouAcceptPaymentsOfficeField />
          </Cell>
        </Row>

        <Row>
          <Cell>
            <WhereDoYouAcceptPaymentsHomeField />
            <WhereDoYouAcceptPaymentsOnlineField />
          </Cell>
        </Row>
        <Row>
          <Cell>
            <WhereDoYouAcceptPaymentsFaceToFaceField />
          </Cell>
        </Row>
      </Table>
    </Divider>

    <Table>
      <Row>
        <Cell>
          <TellUsMoreAboutYourBusinessLabel />
        </Cell>
      </Row>
      <Row>
        <Cell>
          <TellUsMoreAboutYourBusinessField />
        </Cell>
      </Row>
    </Table>

    <GotchaField />
  </React.Fragment>
);

export default MobileFieldset;
